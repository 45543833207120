@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.content {
  &::-webkit-scrollbar {
    display: none;
  }
  p {
    @apply mt-2;
  }
  li,
  p {
    @apply text-sm;
  }
  b {
    @apply font-bold;
  }
  h2 {
    @apply text-lg mt-6 font-bold;
  }
  h3 {
    @apply text-base mt-3 font-bold;
  }
  ul {
    @apply list-disc;
  }
  li {
    @apply ml-6 mt-3;
  }
  a {
    @apply text-blue-400;
  }
}
.slick-prev:before,
.slick-next:before {
  font-size: 30px;
  line-height: 1;
  opacity: 0.75;
  color: white;
}

.slick-track {
  /* margin-left: 0; */
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
@keyframes animate-gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  background-size: 300% 100%; /* Important for the gradient animation */
  animation: animate-gradient 3s linear infinite; /* Adjust duration and timing function as needed */
}
